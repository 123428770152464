import React, { useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios from "../services/axios";
import AuthContext from "./AuthContext";
import { useSearchParams } from "react-router-dom";

const ContentItemContext = createContext();

const generate_updatable_item = (contentItem, locales, current_model) => {
	const default_locale = locales[0].locale;
	const updatable = { [default_locale]: {}, status: contentItem.status };

	for (let index = 0; index < current_model.fields.length; index++) {
		const field = current_model.fields[index];
		if (!field.is_localized) {
			updatable[default_locale][field.field_id] =
				contentItem.fields[field.field_id] == null
					? ""
					: contentItem.fields[field.field_id];
		} else {
			for (let index = 0; index < locales.length; index++) {
				const locale = locales[index];
				if (!updatable[locale.locale]) {
					updatable[locale.locale] = {};
				}
				if (
					contentItem.fields["@localized"] &&
					contentItem.fields["@localized"][locale.locale] &&
					contentItem.fields["@localized"][locale.locale][
						field.field_id
					]
				) {
					updatable[locale.locale][field.field_id] =
						contentItem.fields["@localized"][locale.locale][
							field.field_id
						];
				} else {
					updatable[locale.locale][field.field_id] = "";
				}
			}
		}
	}

	return updatable;
};

export const ContentItemProvider = ({ children, model_id, entry_id }) => {
	const [model, setModel] = useState(null);
	const [contentItem, setContentItem] = useState(null);
	const [updatableItem, setUpdatableItem] = useState(null);
	const [currentLocale, setCurrentLocale] = useState(null);
	const [loading, setLoading] = useState(false);
	const { locales, models } = useContext(AuthContext);
	const updatableItemBase = useRef(null);
	const can_save = useRef(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const load_item = async () => {
		const item = await axios
			.get(`content/${model_id}/${entry_id}/`)
			.then((e) => e.data)
			.catch((e) => null);
		setContentItem(item);
		const current_model = models.filter((e) => e.model_id == model_id)[0];
		setModel(current_model);
		const up = generate_updatable_item(item, locales, current_model);
		setUpdatableItem(up);
		updatableItemBase.current = JSON.stringify(up);

		const default_locale = locales[0].locale;
		const current_locale_code = currentLocale
			? currentLocale.locale
			: searchParams.get("locale")
			? searchParams.get("locale")
			: default_locale;

		if (!currentLocale || current_locale_code != currentLocale.locale) {
			const current_locale = locales.filter(
				(e) => e.locale == current_locale_code
			)[0];
			setCurrentLocale(current_locale);
		}
	};

	const update_locale = (code) => {
		const current_locale = locales.filter((e) => e.locale == code)[0];
		setCurrentLocale(current_locale);
		setSearchParams({ locale: current_locale.locale });
	};

	const update_item = (locale, field_id, value) => {
		const copy = JSON.parse(JSON.stringify(updatableItem));
		copy[locale][field_id] = value;
		can_save.current = JSON.stringify(copy) != updatableItemBase.current;
		setUpdatableItem(copy);
	};

	const update_status = (status) => {
		const copy = JSON.parse(JSON.stringify(updatableItem));
		copy.status = status;
		can_save.current = JSON.stringify(copy) != updatableItemBase.current;
		setUpdatableItem(copy);
	};

	const save = async () => {
		const base = JSON.parse(updatableItemBase.current);

		const to_update = [];

		for (const [loc_key, entry] of Object.entries(base)) {
			if (loc_key == "status") continue;
			const up = {};
			for (const [field_id, value] of Object.entries(entry)) {
				if (field_id != "id") {
					if (value != updatableItem[loc_key][field_id]) {
						up[field_id] = updatableItem[loc_key][field_id];
						const type = model.fields.filter(
							(e) => e.field_id == field_id
						)[0].type;
						if (type == "integer") {
							up[field_id] = parseInt(up[field_id]);
						} else if (type == "float") {
							up[field_id] = parseFloat(up[field_id]);
						} else if (
							(type == "media" || type == "ref") &&
							up[field_id] == ""
						) {
							up[field_id] = null;
						}
					}
				}
			}
			if (Object.keys(up).length) {
				up["locale"] = loc_key;
				to_update.push(up);
			}
		}

		setLoading(true);

		for (let index = 0; index < to_update.length; index++) {
			const item = to_update[index];
			const locale = item["locale"];
			delete item["locale"];
			const updated = await axios
				.patch(
					`content/${model_id}/${entry_id}/?locale=${locale}`,
					item
				)
				.then((e) => e.data)
				.catch((e) => null);
			if (updated != null) {
				updatableItemBase.current = JSON.stringify(updatableItem);
				can_save.current = false;
			}
		}

		if (
			updatableItem.status != JSON.parse(updatableItemBase.current).status
		) {
			const updated = await axios
				.put(`content/${model_id}/${entry_id}/`, {
					status: updatableItem.status,
				})
				.then((e) => e.data)
				.catch((e) => null);
			if (updated) {
				updatableItemBase.current = JSON.stringify(updatableItem);
				can_save.current = false;
			}
		}

		setLoading(false);
	};

	useEffect(() => {
		if (entry_id == "new") {
			const current_model = models.filter(
				(e) => e.model_id == model_id
			)[0];
			setModel(current_model);
		} else {
			load_item();
		}
	}, [model_id, entry_id]);

	useEffect(() => {
		if (locales.length) {
			setCurrentLocale(locales[0]);
		}
	}, [locales]);

	return (
		<ContentItemContext.Provider
			value={{
				model,
				contentItem,
				currentLocale,
				updatableItem,
				update_locale,
				update_status,
				update_item,
				can_save,
				save,
				loading,
			}}
		>
			{children}
		</ContentItemContext.Provider>
	);
};

export default ContentItemContext;
