import React, { useContext } from "react";
import "./style.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ContentItemContext from "../../../../contexts/ContentItemContext";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../contexts/AuthContext";
import { LoadingButton } from "@mui/lab";

const ContentItemAside = () => {
	const {
		currentLocale,
		update_locale,
		can_save,
		save,
		loading,
		updatableItem,
		update_status,
		model,
	} = useContext(ContentItemContext);
	const { locales } = useContext(AuthContext);
	const { t } = useTranslation("locales");

	if (!locales || !currentLocale || !updatableItem) return null;

	return (
		<aside className="content-item-aside">
			<div className="save-btn">
				<LoadingButton
					disabled={!can_save.current}
					onClick={save}
					variant="contained"
					loading={loading}
					fullWidth
				>
					Save changes
				</LoadingButton>
			</div>

			<FormControl style={{ marginTop: "20px" }} fullWidth>
				<InputLabel id="demo-simple-select-label">Status</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={updatableItem?.status ? updatableItem.status : ""}
					label="Status"
					onChange={(e) => update_status(e.target.value)}
					size="small"
				>
					<MenuItem value={"draft"}>Draft</MenuItem>
					<MenuItem value={"published"}>Published</MenuItem>
					<MenuItem value={"archive"}>Archive</MenuItem>
				</Select>
			</FormControl>

			{model &&
				model.fields.filter((e) => e.is_localized).length != 0 && (
					<div className="translation-container">
						<span className="translation-title">Translations</span>
						<Select
							size="small"
							labelId="demo-simple-select-label"
							className="translation-selector"
							value={currentLocale.locale}
							onChange={(e) => update_locale(e.target.value)}
						>
							{locales.map((local, index) => (
								<MenuItem key={index} value={local.locale}>
									{t(local.locale)}
								</MenuItem>
							))}
						</Select>
					</div>
				)}
		</aside>
	);
};

export default ContentItemAside;
