import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";

const DateField = ({ label, value, onChange }) => {
	if (value && typeof value == "string") {
		value = dayjs(value);
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				renderInput={(props) => <TextField {...props} size="small" />}
				label={label}
				value={value}
				onChange={(newValue) => {
					onChange(newValue);
				}}
			/>
		</LocalizationProvider>
	);
};

export default DateField;
